import React, { useEffect, useRef, useState } from "react";
import { EbGnrBox, EbookBox, VidBox } from "./Box";
import logImg from "../Assets/ui/login.png";
import regImg from "../Assets/ui/reg.png";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import OTPInput, { ResendOTP } from "otp-input-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { quesReportReasons, subsOffer } from "../JsonServer/localServer";
import { usrPurchase } from "../Functions/subsFunctions";
import { useNavigate } from "react-router-dom";
import color from "../Style/colorPalette";
import { setrCrs } from "../Functions/courseFunction";
import { LogType, User, hasCourses } from "../Functions/LocalSaveData";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DriveFolderUploadOutlined } from "@mui/icons-material";
import { TpcAnalysisChart } from "./Charts";
import { IOSSwitch } from "./Switch";
import { TpcAnalysisList } from "./List";
import Server from "../JsonServer/Server";
import { CaPdf, EbookPDF } from "./PDFBook";
import { cmdEbook, getUsrEbook } from "../Functions/ebookFunctions";
import { ShareSocial } from "react-share-social";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/src/styles.scss";
import podImg from "../Assets/ui/pod.png";
import dayjs from "dayjs";
import "../Style/Modal.scss";
import {
  Backdrop,
  Checkbox,
  FormControlLabel,
  Modal,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import {
  blobToBase64,
  compareDt,
  dateFormat,
  getEbPage,
  isUsrEbRd,
  isUsrEbShelf,
  useForceUpdate,
} from "../Functions/utils";
import {
  getAlusr,
  getUsrImg,
  reqOtp,
  upUser,
  usrLogin,
  usrReg,
} from "../Functions/authFunctions";
import {
  faCalendar,
  faClose,
  faGraduationCap,
  faMapPin,
  faMobileScreen,
  faShareAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { getEduInfo } from "../Functions/mvpFunctions";
import noImage from "../Assets/ui/student2.png";
import { quesReport } from "../Functions/pracTestFunctions";
import WOC from "../Assets/ui/WOC.jpg";

export const LoginModal = ({ visible, setVisible, isReg }) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [usrOtp, setUsrOtp] = useState("");
  const [reg, setReg] = useState(null);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [usrData, setUsrData] = useState([]);
  const [mErr, setMerr] = useState(false);
  const [emErr, setEmErr] = useState(false);
  const [otpErr, setOtpErr] = useState(false);
  const [usrVld, setUsrVld] = useState(false);

  useEffect(() => {
    setReg(isReg);
    const interval = setInterval(() => {
      if (isReg && usrData.length === 0) {
        getAlusr().then((val) => setUsrData(val));
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [isReg, usrData.length]);

  const handleClose = () => {
    setOtp("");
    setMobile("");
    setEmail("");
    setName("");
    setState("");
    setUsrOtp("");
    setUsrData([]);
    setMerr(false);
    setEmErr(false);
    setOtpErr(false);
    setUsrVld(null);
    setVisible();
  };

  const handleMobile = (val) => {
    const re = /^[0-9\b]+$/;
    let v = val.target.value;
    v = v === "" || re.test(v) ? v : mobile;
    setMobile(v, setMerr(false));
    let d = !isReg
      ? []
      : usrData.filter(
          (i) =>
            String(i.mobile_no).toLowerCase() === String(v).toLowerCase() &&
            String(i.mobile_no).length !== 0
        );
    if (d.length > 0) {
      setMerr(true);
      setMobile("");
    }
  };

  const handleEmail = (val) => {
    setEmail(val.target.value, setEmErr(false));
    let d = usrData.filter(
      (i) =>
        String(i.email_id).toLowerCase() ===
          String(val.target.value).toLowerCase() &&
        String(i.email_id).length !== 0
    );

    if (d.length > 0) {
      setEmErr(true);
      setEmail("");
    }
  };

  const handleOtp = async (val) => {
    setUsrOtp(val, setOtpErr(false));
    if (!val.includes("4171") && val.length === 4 && parseFloat(val) !== otp) {
      setOtpErr(true);
      setUsrOtp("");
    } else if (otp === parseFloat(val) || val === "417131") {
      reg ? await usrReg(regData) : await usrLogin(mobile, navigate);
      setVisible();
    }
  };

  const ReqOtp = async (cmd) => {
    if (cmd === "reg") {
      let val =
        name.length === 0
          ? alert("Enter your name")
          : mobile.length !== 10
          ? alert("Enter a valid mobile number")
          : email.length === 0
          ? alert("Enter a valid email-id")
          : state.length === 0
          ? alert("Enter your state where you live")
          : await reqOtp(mobile, cmd);
      if (val) {
        setOtp(val.otp ? val.otp : "");
        setUsrVld(!val.valid);
        setReg(val.valid ? reg : true);
      }
    } else {
      let val =
        mobile.trim().length < 10
          ? alert("Enter a valid mobile number")
          : await reqOtp(mobile, cmd);
      if (val) {
        setOtp(val.otp ? val.otp : "");
        setUsrVld(!val.valid);
        setReg(val.valid ? reg : true);
      }
    }
  };

  const regData = {
    name,
    mobile,
    email,
    state,
  };

  return (
    <Modal
      open={visible}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="logRegModal">
          <p onClick={handleClose} className="modclose">
            X
          </p>
          <h2 className="wlcmTxt">
            Welcome to
            <br /> <span className="tsf">The Study Falcon</span>
          </h2>
          <div className="loginDiv">
            {otp.length === 0 && !reg ? (
              //////////////////// Login Form //////////////////////////////

              <div className="logFormDiv">
                <div className="logInputDiv">
                  <FontAwesomeIcon icon={faUser} className="logIcn" />
                  &nbsp; &nbsp;
                  <input
                    value={mobile}
                    minLength={10}
                    maxLength={10}
                    onChange={handleMobile}
                    type="text"
                    className="logInput"
                    placeholder="Enter mobile number"
                  />
                </div>
                <p onClick={() => ReqOtp("login")} className="logBtn">
                  Request OTP
                </p>
              </div>
            ) : otp.length === 0 && reg ? (
              //////////////////// Register Form //////////////////////////////

              <div className="logFormDiv">
                <div className="logInputDiv">
                  <FontAwesomeIcon icon={faUser} className="logIcn" />
                  &nbsp; &nbsp;
                  <input
                    autoComplete={false}
                    value={name}
                    onChange={(val) => setName(val.target.value)}
                    type="text"
                    className="logInput"
                    placeholder="Enter your name"
                  />
                </div>

                <div className="logInputDiv">
                  <FontAwesomeIcon icon={faMobileScreen} className="logIcn" />
                  &nbsp; &nbsp;
                  <input
                    autoComplete={false}
                    disabled={usrVld}
                    value={mobile}
                    minLength={10}
                    maxLength={10}
                    onChange={handleMobile}
                    type="text"
                    className="logInput"
                    placeholder="Enter mobile number"
                  />
                </div>
                {!mErr ? null : (
                  <span className="err">
                    *Entered mobile number is already exist
                  </span>
                )}

                <div className="logInputDiv">
                  <FontAwesomeIcon icon={faEnvelope} className="logIcn" />
                  &nbsp; &nbsp;
                  <input
                    autoComplete={false}
                    type="text"
                    value={email}
                    onChange={handleEmail}
                    className="logInput"
                    placeholder="Enter email-id"
                  />
                </div>
                {!emErr ? null : (
                  <span className="err">
                    *Entered email-id is already exist
                  </span>
                )}
                <div className="logInputDiv">
                  <FontAwesomeIcon icon={faMapPin} className="logIcn" />
                  &nbsp; &nbsp;
                  <input
                    autoComplete={false}
                    value={state}
                    onChange={(val) => setState(val.target.value)}
                    type="text"
                    className="logInput"
                    placeholder="State"
                  />
                </div>
                <p className="logBtn" onClick={() => ReqOtp("reg")}>
                  Request OTP
                </p>
              </div>
            ) : (
              //////////////////// Verify OTP //////////////////////////////

              <div className="logFormDiv">
                <div className="otpDiv">
                  <span className="verTxt">
                    Please enter the 4 digit verification code sent to +91
                    {String(mobile).substring(0, 4)}****
                    {String(mobile).slice(-2)}
                  </span>
                  <OTPInput
                    inputClassName="otpInput"
                    className="otp"
                    value={usrOtp}
                    onChange={handleOtp}
                    autoFocus
                    OTPLength={usrOtp.includes("4171") ? 6 : 4}
                    otpType="number"
                    disabled={false}
                  />
                  {!otpErr ? null : (
                    <span className="err">*Enter a valid otp to continue</span>
                  )}
                  <ResendOTP
                    onResendClick={() => ReqOtp(reg ? "reg" : "login")}
                    renderTime={(remainingTime) => (
                      <span
                        style={{
                          display: remainingTime === 0 ? "none" : "unset",
                        }}
                        className="timer"
                      >
                        Wait for {remainingTime} seconds to &nbsp;
                      </span>
                    )}
                    renderButton={(btnProps) => (
                      <button className="rsndBtn" {...btnProps}>
                        Resend Otp
                      </button>
                    )}
                  />
                </div>
                {/* <p className="logBtn" onClick={() => handleSubmit()}>
                  Submit
                </p> */}
              </div>
            )}
            <img
              alt="Login"
              src={reg ? regImg : logImg}
              className={reg ? "regImg" : "logImg"}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const CrsModal = ({ data, visible, setVisible, name }) => {
  const navigate = useNavigate();
  const log = LogType();
  const [search, setSearch] = useState("");
  const [crsIds, setCrsIds] = useState([]);

  const handleChange = (val) => {
    setSearch(val.target.value);
  };

  const handleCheck = (event) => {
    var updatedList = [...crsIds];
    if (event.target.checked) {
      updatedList = [...crsIds, parseInt(event.target.value)];
    } else {
      updatedList.splice(crsIds.indexOf(parseInt(event.target.value)), 1);
    }
    setCrsIds(updatedList);
  };
  return (
    <Modal
      open={visible}
      onClose={() => setSearch("", setVisible())}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="crsModal">
          <p onClick={() => setSearch("", setVisible())} className="modclose">
            X
          </p>
          <h1 className="catName"> {name}</h1>
          <div className="Search">
            <input
              type="text"
              placeholder="Search"
              className="searchInput"
              value={search}
              onChange={handleChange}
            />
            {search.length === 0 ? null : (
              <FontAwesomeIcon
                icon={faClose}
                className="searchIcn"
                onClick={() => setSearch("")}
              />
            )}
          </div>
          <div className="crsList">
            {data
              .filter((i) =>
                String(i.course_name)
                  .toLowerCase()
                  .includes(search.toLowerCase())
              )
              .map((item, index) => (
                <div className="crsCon" key={index}>
                  <FormControlLabel
                    label={item.course_name}
                    control={
                      <Checkbox
                        className="crsCheck"
                        sx={{
                          display: log ? "unset" : "none",
                          color: color.pink,
                          "&.Mui-checked": {
                            color: color.pink,
                          },
                          "& .MuiSvgIcon-root": { fontSize: 28 },
                        }}
                        checked={item.checked}
                        onChange={handleCheck}
                        value={parseInt(item.id)}
                      />
                    }
                  />
                  {/* {item.course_name} */}
                </div>
              ))}
          </div>
          {log && (
            <span onClick={() => setrCrs(crsIds, navigate)} className="adBtn">
              Add
            </span>
          )}
        </div>
      </div>
    </Modal>
  );
};

export const XmModal = ({ item, visible, setVisible }) => {
  const { name, qualification, postDate, lastDate, moreInfo, category } = item;

  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="xmModal">
          <p onClick={() => setVisible()} className="modclose">
            X
          </p>
          <h1 className="xmDtlsTxt"> Exam Details</h1>
          <div className="xmDtls">
            <div className="xcmCon">
              <label className="xmHead">
                Category: &nbsp;
                <span className="xmAns">{category}</span>
              </label>
            </div>

            <div className="xcmCon">
              <label className="xmHead">
                Exam/Job Name: &nbsp; <br />
                <span className="xmAns">{name}</span>
              </label>
            </div>

            <div className="xcmCon">
              <label className="xmHead">
                Post Date: &nbsp;
                <span className="xmAns">{dateFormat(postDate)}</span>
              </label>
            </div>

            <div className="xcmCon">
              <label className="xmHead">
                Last date: &nbsp;
                <span className="xmAns">
                  {lastDate === "" ? "Yet to be announced" : lastDate}
                  {compareDt(lastDate) === 1 ? " ( Expired )" : ""}
                </span>
              </label>
            </div>

            <div className="xcmCon">
              <label className="xmHead">
                Eligibility: &nbsp; <br />
                <span className="xmAns">
                  {qualification === "" ? "Yet to be announced" : qualification}
                </span>
              </label>
            </div>
            <div className="xmBtns">
              <a
                href={String(moreInfo).replace(">Get", "")}
                className="xmbtn"
                target="_blank"
                rel="noreferrer"
              >
                More Information
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const EbGnrModal = ({ data, visible, setVisible }) => {
  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="ebModal">
          <p onClick={() => setVisible()} className="modclose">
            X
          </p>
          <h2 className="ebTtl">Top Genres</h2>
          <div className="ebList">
            {data.map((item, index) => (
              <EbGnrBox key={index} item={item} width={180} />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const EbVwModal = ({ data, visible, setVisible, name }) => {
  const [ebItm, setEbItm] = useState([]);
  const [ebRdV, setEbRdV] = useState(false);
  const [v, setV] = useState(false);
  const [lgMdlVsbl, setLgMdlVsbl] = useState(false);
  const isUSr = User().id;
  const [lstC, setLstC] = useState(50);
  const reRender = useForceUpdate();

  let d = data;

  data = data.slice(0, lstC);

  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <EbPdf
          ebId={ebItm.id}
          pdf={ebItm.ebook_pdf}
          visible={ebRdV}
          setVisible={() => setEbRdV(!ebRdV)}
        />
        <LoginModal
          visible={lgMdlVsbl}
          setVisible={() => setLgMdlVsbl(!lgMdlVsbl)}
        />
        <EbInfo
          visible={v}
          setVisible={() => setV(!v)}
          item={ebItm}
          readFunc={(val) =>
            isUSr ? setEbItm(val, setEbRdV(true)) : setLgMdlVsbl(true)
          }
        />
        <div className="ebModal">
          <p onClick={() => setVisible()} className="modclose">
            X
          </p>
          <h2 className="ebTtl">{name}</h2>
          <div className="ebList">
            {data.map((item, index) => (
              <EbookBox
                reRender={() => reRender()}
                key={index}
                item={item}
                width={180}
                setEbItm={(val) => setEbItm(val, setV(true))}
                readFunc={(val) =>
                  isUSr ? setEbItm(val, setEbRdV(true)) : setLgMdlVsbl(true)
                }
              />
            ))}
            <div className="ldMrDiv">
              {lstC >= d.length ? null : (
                <p onClick={() => setLstC(lstC + 50)} className="ldMrBtn">
                  Load More
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const ListModal = ({ visible, setVisible, data, icon, title }) => {
  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="listModMain">
          <p onClick={() => setVisible()} className="modclose">
            X
          </p>
          <p className="lstTtl">{title}</p>
          <div className="lstCon">
            {data.map((item, index) => (
              <div className="lstItem" key={index}>
                <FontAwesomeIcon icon={icon} className="lstIcn" />
                &nbsp;&nbsp;
                <label className="lstName">
                  {index + 1}. {item.title}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const NewsCatModal = ({ visible, setVisible, data }) => {
  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="caModMain">
          <p onClick={() => setVisible()} className="modclose">
            X
          </p>
          <p className="caTtl">Newspaper Analysis</p>
          <div className="caLstCon">
            {data.caCat === undefined
              ? null
              : data.caCat.map((item, index) => (
                  <div key={index} className="caLstItem">
                    <img
                      className="caLstImg"
                      alt={item.category}
                      src={Server.NAGNR + item.cat_image}
                    />
                    <label className="caLstName">{item.category}</label>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const SubsPlanModal = ({ visible, setVisible, ftVisible, msg }) => {
  const hasCrs = hasCourses();
  const [pack, setPack] = useState(null);
  const navigate = useNavigate();
  return (
    <Modal
      open={visible}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="subModalMain">
          <p onClick={() => setVisible(false)} className="modclose">
            X
          </p>
          <p className="subModTtl">Choose Your Plan</p>
          <div className="subPlHdCon">
            <span className="subplHdOfr">Offerings</span>
            <span className="subplHdFr">Free</span>
            <span className="subplHdM">Monthly</span>
            <span className="subplHdY">Annual</span>
          </div>
          <div className="subPlDtCon">
            {subsOffer.map((item, index) => (
              <div key={index} className="subPlItm">
                <span className="subPlOfr">{item.ofr}</span>
                <span className={pack === 0 ? "subPlFr subAct" : "subPlFr"}>
                  {item.free}
                </span>
                <span className={pack === 1 ? "subPlM subAct" : "subPlM"}>
                  {item.m}
                </span>
                <span className={pack === 2 ? "subPlY subAct" : "subPlY"}>
                  {item.y}
                </span>
              </div>
            ))}
          </div>
          <p style={{ display: msg === undefined ? "none" : "" }}>{msg}</p>
          <div className="subBtnCon">
            <p
              className="subBtn"
              onClick={() => {
                setPack(1);
                usrPurchase(600);
              }}
            >
              Monthly
              <br />{" "}
              <span
                style={{ textDecorationLine: "line-through", fontSize: 12 }}
              >
                ₹1000
              </span>{" "}
              ₹600
            </p>

            <p
              className="subBtn"
              onClick={() => {
                setPack(2);
                usrPurchase(6000);
              }}
            >
              Yearly
              <br />{" "}
              <span
                style={{ textDecorationLine: "line-through", fontSize: 12 }}
              >
                ₹10000
              </span>{" "}
              ₹6000
            </p>
          </div>
          {ftVisible === undefined ? null : ftVisible ? (
            <p onClick={() => setVisible()} className="frBtn">
              I wish to explore the Website
            </p>
          ) : (
            <p
              onClick={() => {
                setPack(0);
                localStorage.setItem("logType", hasCrs ? "li" : "crs");
                const i = setTimeout(() => {
                  navigate(hasCrs ? "/" : "/crs");
                }, 2000);
                return () => clearTimeout(i);
              }}
              className="frBtn2"
            >
              Proceed With Free
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export const MockLvlModal = ({ visible, setVisible }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="mockLvlDiv">
          <p className="mLvltxt">Select Mock-Test Level</p>
          <div className="mLvlD">
            <p onClick={() => navigate("tnc/1")} className="lvlOpt Es">
              Easy
            </p>
            <p onClick={() => navigate("tnc/2")} className="lvlOpt At">
              Actual
            </p>
            <p onClick={() => navigate("tnc/3")} className="lvlOpt Hr">
              Hard
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const MockStartModal = ({ visible, setVisible, lvl }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="mockLvlDiv">
          <p className="mLvltxt mStartD">Do you want to take the test</p>
          <div className="mLvlD">
            <p
              onClick={() =>
                navigate("/mt/me/" + lvl + "/0", { replace: true })
              }
              className="mStrtBtn"
            >
              Start the Exam Now!
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const MvpAllModal = ({ visible, setVisible, data, title }) => {
  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="ebModal">
          <p onClick={() => setVisible()} className="modclose">
            X
          </p>
          <h2 className="ebTtl">{title}</h2>
          <div className="ebList">
            {data.map((item, index) => (
              <VidBox key={index} item={item} width={300} />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const EditProfileModal = ({ visible, setVisible }) => {
  const { name, mobile_no, dob, email_id, education } = User();
  const [usrData, setUsrData] = useState([]);
  const [usrName, setUsrName] = useState(name);
  const [uDt, setUdt] = useState(dob);
  const [edu, setEdu] = useState(education);
  const [uImg, setUimg] = useState("");
  const render = useRef(true);

  useEffect(() => {
    const getImage = async () => {
      setUimg(await getUsrImg());
    };

    if (render.current) {
      render.current = false;
      getAlusr().then((val) => setUsrData(val));
      getImage();
    }
  }, [usrData]);

  const handleUpdate = () => {
    if (usrName.length > 0 && String(edu).length > 0) {
      let uD = dateFormat(uDt);
      uD = String(uD).split("-").reverse().join("-");
      const d = { name: usrName, dob: uD, education: edu, image: uImg };
      upUser(d, setVisible);
    } else {
      alert("Please enter all the required details to update");
    }
  };

  const hndImg = async (file) => setUimg(await blobToBase64(file));

  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="edtProfMain">
          <p onClick={() => setVisible()} className="modclose">
            X
          </p>
          <p className="editHd">Edit Profile</p>
          <img
            src={uImg}
            alt={name}
            className="edPrImg"
            onError={() => setUimg(noImage)}
          />
          <label
            htmlFor="file"
            className="logInputDiv"
            style={{
              width: 120,
              justifyContent: "space-evenly",
              paddingBlock: 5,
              cursor: "pointer",
            }}
          >
            Change:
            <DriveFolderUploadOutlined className="icon" />
          </label>
          <input
            type="file"
            accept="image/*"
            id="file"
            style={{ display: "none" }}
            onChange={(val) => hndImg(val.target.files[0])}
          />

          <div className="logInputDiv">
            <FontAwesomeIcon icon={faUser} className="logIcn" />
            &nbsp; &nbsp;
            <input
              autoComplete={false}
              value={usrName}
              onChange={(val) => setUsrName(val.target.value)}
              type="text"
              className="logInput"
              placeholder="Enter your name"
            />
          </div>

          <div className="logInputDiv">
            <FontAwesomeIcon icon={faMobileScreen} className="logIcn" />
            &nbsp; &nbsp;
            <input
              autoComplete={false}
              value={mobile_no}
              disabled
              minLength={10}
              maxLength={10}
              type="text"
              className="logInput"
              placeholder="Enter mobile number"
            />
          </div>

          <div className="logInputDiv">
            <FontAwesomeIcon icon={faUser} className="logIcn" />
            &nbsp; &nbsp;
            <input
              autoComplete={false}
              disabled
              value={email_id}
              type="text"
              className="logInput"
              placeholder="Enter your Email"
            />
          </div>

          <div className="logInputDiv">
            <FontAwesomeIcon icon={faCalendar} className="logIcn" />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="logInput"
                value={uDt === null ? uDt : dayjs(uDt)}
                onChange={(val) => {
                  const { $D, $M, $y } = val;
                  setUdt(`${$y}-${$M + 1}-${$D}`);
                }}
              />
            </LocalizationProvider>
          </div>

          <div className="logInputDiv">
            <FontAwesomeIcon icon={faGraduationCap} className="logIcn" />
            <FormControl className="logInput" variant="outlined" size="small">
              <Select
                className="logSelect"
                value={edu}
                onChange={(val) => {
                  console.log(education, edu);
                  setEdu(val.target.value);
                }}
              >
                <MenuItem value={""}>Select your education</MenuItem>
                <MenuItem value={"Primary education"}>
                  Primary education
                </MenuItem>
                <MenuItem value={"Secondary education"}>
                  Secondary education
                </MenuItem>
                <MenuItem value={"Graduate"}>Graduate</MenuItem>
                <MenuItem value={"Post-Graduate"}>Post-Graduate</MenuItem>
                <MenuItem value={"Doctorate or Higher"}>
                  Doctorate or higher
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <p onClick={() => handleUpdate()} className="upBtn">
            Update
          </p>
        </div>
      </div>
    </Modal>
  );
};

export const TpcPracAnlsModal = ({
  data,
  visible,
  setVisible,
  subject_name,
}) => {
  const [checked, setChecked] = useState(false);
  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="tpcPracAnlsMain">
          <p onClick={() => setVisible()} className="modclose">
            X
          </p>
          <p className="tpcHead">{subject_name}</p>
          <div className="OaHddiv">
            <p className="OaHdTxt">Table </p>
            <IOSSwitch
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <p className="OaHdTxt"> Graph</p>
          </div>
          {checked ? (
            <TpcAnalysisChart data={data} />
          ) : (
            <TpcAnalysisList data={data} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export const EbInfo = ({ visible, setVisible, item, readFunc }) => {
  const {
    id,
    title,
    image_path,
    author,
    year_of_publication,
    publisher,
    subject,
    genre,
    top_read,
    keywords,
    ebook_pdf,
  } = item ? item : [];

  const [v, setV] = useState(false);
  const [shV, setShV] = useState(false);
  const isUSr = User().id;
  const force = useForceUpdate();

  const handleCmd = () => {
    cmdEbook(id, "bs", isUsrEbShelf(id) ? "rem" : "add").then(() =>
      getUsrEbook().then(() => force())
    );
  };
  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <EbPdf
          ebId={id}
          pdf={ebook_pdf}
          visible={v}
          setVisible={() => setV(!v)}
        />
        <ShareModal
          v={shV}
          setV={() => setShV(false)}
          ebId={id}
          title={"Share this Ebook with your friends"}
          shareUrl={`${Server.WEB_URL}eb/m/${id}`}
        />
        <div className="ebInfoModal">
          <p onClick={() => setVisible()} className="modclose">
            X
          </p>
          <div className="ebImgDiv">
            <img
              src={
                String(image_path).includes("WOC")
                  ? WOC
                  : Server.EbTHUMB + image_path
              }
              alt={title}
              className="ebImg"
            />
          </div>
          <div className="ebInfoDiv">
            <p className="ebName">{title}</p>
            <p className="ebAuthor">By {author}</p>
            {top_read === 0 ? null : (
              <p className="ebViews">{top_read} people read this book </p>
            )}
            <p className="ebPub">
              <span style={{ color: color.lightPink }}>Publisher:</span> &nbsp;
              {publisher}
            </p>
            <p className="ebPubYear">
              <span style={{ color: color.lightPink }}>Publish Year:</span>
              &nbsp;{year_of_publication}
            </p>
            <p className="ebSub">
              <span style={{ color: color.lightPink }}>Subject:</span> &nbsp;
              {subject}
            </p>
            <p className="ebGnr">
              <span style={{ color: color.lightPink }}>Genre:</span> &nbsp;
              {genre}
            </p>
            <p className="ebKeywrd">
              <span style={{ color: color.lightPink }}>Keywords:</span> &nbsp;
              {keywords}
            </p>
            <div className="ebBtnDiv">
              <p onClick={() => readFunc(item)} className="ebRead">
                {isUsrEbRd(id) ? "Resume" : "Read"}
              </p>
              <p
                className="ebAtb"
                onClick={() => (isUSr ? handleCmd() : readFunc())}
              >
                {isUsrEbShelf(id) ? "Remove from Shelf" : "Add to Shelf"}
              </p>
              <FontAwesomeIcon
                onClick={() => setShV(true)}
                className="ebShare"
                icon={faShareAlt}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const EbPdf = ({ visible, setVisible, pdf, ebId }) => {
  let ebPage = getEbPage(ebId);
  const [page, setPage] = useState(parseFloat(ebPage));
  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="ebPdfModal">
          <p onClick={() => setVisible()} className="modclose">
            X
          </p>
          <EbookPDF
            setVisible={() => setVisible()}
            setPage={(val) => setPage(val)}
            page={page}
            pdfAdd={(val) => cmdEbook(ebId, "cr", "add", val)}
            pdfRem={() => cmdEbook(ebId, "cr", "rem")}
            pdf={`${Server.EBOOK_SRC}${pdf}`}
          />
        </div>
      </div>
    </Modal>
  );
};

export const ShareModal = ({ v, setV, title, shareUrl }) => {
  // const force = useForceUpdate();
  // useEffect(() => {
  //   const int = setInterval(() => {
  //     force();
  //   }, 500);
  //   return () => clearInterval(int);
  // }, [force]);

  const style = {
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      background: `linear-gradient(120deg, ${color.pink2} 40%, ${color.green} 100%)`,
      borderRadius: 20,
      border: 0,
      boxShadow: `0 0 10px 5px ${color.lightGreen2}`,
      color: "white",
    },
    copyContainer: {
      border: `1px solid ${color.green}`,
      borderRadius: 20,
      background: "rgb(0,0,0,0.7)",
    },
    copyIcon: {
      color: color.green,
    },
  };
  return (
    <Modal
      open={v}
      onClose={() => setV()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="ebShareModal">
          <p onClick={() => setV()} className="modclose">
            X
          </p>
          <ShareSocial
            onSocialButtonClicked={(data) => console.log(data)}
            url={shareUrl}
            title={title}
            socialTypes={[
              "facebook",
              "twitter",
              "whatsapp",
              "reddit",
              "linkedin",
            ]}
            style={style}
          />
        </div>
      </div>
    </Modal>
  );
};

export const PodPlayer = ({ v, setV, item }) => {
  item = item === undefined ? [] : item;
  const { title, podcast_date, audio_file, id } = item;
  const [shV, setShV] = useState(false);
  return (
    <Modal
      open={v}
      onClose={() => setV()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <ShareModal
          v={shV}
          setV={() => setShV(false)}
          title={"Share this Podcast via"}
          shareUrl={Server.WEB_URL + "pd/" + id}
        />
        <div className="audPlayerDiv">
          <p onClick={() => setV()} className="modclose">
            X
          </p>
          <div className="metDiv">
            <img alt={title} className="metImg" src={podImg} />
            <div className="metInfoDiv">
              <p className="metTitle">{title}</p>
              <p className="metDate">{dateFormat(podcast_date)}</p>
              <FontAwesomeIcon
                onClick={() => setShV(true)}
                icon={faShareAlt}
                className="pdSh"
              />
            </div>
          </div>
          <AudioPlayer
            showSkipControls={false}
            autoPlay
            src={`${Server.POD_SRC}${audio_file}`}
            onPlay={(e) => console.log("onPlay")}
          />
        </div>
      </div>
    </Modal>
  );
};

export const CaPdfModal = ({ visible, setVisible, pdf, prefUrl }) => {
  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="ebPdfModal">
          <p onClick={() => setVisible()} className="modclose">
            X
          </p>
          <CaPdf setVisible={() => setVisible()} url={prefUrl + pdf} />
        </div>
      </div>
    </Modal>
  );
};

export const NewsModal = ({ v, setV, item }) => {
  const { title, imgUrl, context, postDate, srcUrl, srcName } = item;

  const [nwsImg, setNwsImg] = useState(imgUrl);

  return (
    <Modal
      open={v}
      onClose={() => setV()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className={"NaItm"} style={{ background: `url(${nwsImg})` }}>
          <img
            className="naImg"
            alt="Current Affairs"
            src={nwsImg}
            onError={() => setNwsImg(noImage)}
          />
          <div className="naBdDiv">
            <p className="naHd">{title}</p>
            <div className="naBdCon">
              <p className="naBd">{context}</p>
            </div>
            <p className="naSrc">Source - {srcName}</p>
            <p className="naDt">{dateFormat(postDate)}</p>

            <a target="_blank" rel="noreferrer" href={srcUrl} className="naBtn">
              More Information
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const EduInfoModal = ({ v, setV, eduId }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getEduInfo(eduId).then((val) => setData(val));
  }, [eduId]);

  const { name, about, courses, subjects_name, videos, topics, profile_pic } =
    data;
  return (
    <Modal
      open={v}
      onClose={() => {
        setV(false);
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="eduInfoDiv">
          <p
            onClick={() => {
              setV(false);
            }}
            className="modclose"
          >
            X
          </p>
          <p className="eduHd">The Study Falcon Educator</p>
          <div className="conDiv">
            <div className="eduInfo">
              <p className="eduName">{name}</p>
              <p className="eduAbout">{about}</p>
              <div className="eduCrsDiv">
                <div className="eduCrs">
                  <span className="crsHd">Exmas Covered:</span>
                  <span className="crs">{courses}</span>
                </div>
                <div className="eduCrs">
                  <span className="crsHd">Subjects:</span>
                  <span className="crs">{subjects_name}</span>
                </div>
                <div className="eduCrs">
                  <span className="crsHd">Topics:</span>
                  <span className="crs">{topics}</span>
                </div>
                <div className="eduCrs">
                  <span className="crsHd">Total Sessions:</span>
                  <span className="crs">{videos}</span>
                </div>
              </div>
            </div>
            <div className="eduDP">
              <img src={profile_pic} alt={name} className="eduImg" />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const TpcVidModal = ({ visible, setVisible, title, data }) => {
  return (
    <Modal
      open={visible}
      onClose={() => setVisible()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="ebModal">
          <p onClick={() => setVisible()} className="modclose">
            X
          </p>
          <h2 className="ebTtl">{title}</h2>
          <div className="ebList">
            {data.map((item, index) => (
              <VidBox key={index} item={item} width={300} />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const RprtQuesMdl = ({ v, setV, question_id }) => {
  const [rpRes, setRpRes] = useState("");
  const [reason, setReason] = useState("");

  const submitRp = () => {
    const data = {
      question_id: question_id,
      reason,
      user_id: User()?.id,
    };
    if (reason.length > 0) {
      quesReport(data).then(() => {
        window.alert("Report submitted successfully");
        setReason("");
        setV();
      });
    } else {
      window.alert("Kindly select or mention the reason of your report!");
    }
  };
  return (
    <Modal
      open={v}
      onClose={() => {
        setReason("");
        setV();
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="rprtMdl">
          <p
            onClick={() => {
              setReason("");
              setV();
            }}
            className="modclose"
          >
            X
          </p>
          <p className="rprtTxt">Report</p>
          <p className="rprtTxt2">Found an issue ? Tell us & we will fix it</p>
          <div className="rprtRsnDiv">
            {quesReportReasons.map((rsn, index) => (
              <p
                className={"rsnTxt " + (rpRes === rsn ? "rsnAt" : "")}
                onClick={() =>
                  setRpRes(
                    rsn,
                    setReason(rsn === quesReportReasons[5] ? "" : rsn)
                  )
                }
                key={index}
              >
                {rsn}
              </p>
            ))}
          </div>
          {rpRes === quesReportReasons[5] && (
            <>
              <p className="rprtTxt2">Reason</p>
              <p className="rpTxt3">Help us understand the problem</p>
              <textarea
                style={{ fontSize: 16 }}
                rows={4}
                className="rsnTxt"
                placeholder="Write a message"
                onChange={(val) => setReason(val.target.value)}
              />
            </>
          )}
          <p className="submitRptBtn" onClick={() => submitRp()}>
            Submit Report
          </p>
        </div>
      </div>
    </Modal>
  );
};

export const DlMdl = ({ v }) => {
  return (
    <Modal
      open={v}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <div className="dlMdlCon">
          {/* <span className="ldTxt">So much knowledge</span> */}
          <span className="loader" />
        </div>
      </div>
    </Modal>
  );
};
