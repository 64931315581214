import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import noImage from "../Assets/ui/student2.png";
import { getNewExams } from "../Functions/landingFunctions";
import { getInshorts } from "../Functions/CAFunctions";
import Server from "../JsonServer/Server";
import podImg from "../Assets/ui/pod.png";
import mgImg from "../Assets/ui/Mag.jpg";
import pibImg from "../Assets/ui/govRep.png";
import newsImg from "../Assets/ui/NewsP.png";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import caImg from "../Assets/ui/news1.png";
import { useForceUpdate } from "../Functions/utils";
import { User, dashData, mvpData, usrEbook } from "../Functions/LocalSaveData";
import Carousel from "react-multi-carousel";
import { Carousel as BannerCrsl } from "react-responsive-carousel";
import "react-multi-carousel/lib/styles.css";
import "../Style/BoxCarousel.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CaNaBox, EbGnrBox, EbookBox, NwsBox, VidBox } from "./Box";
import {
  CrsSlides,
  PopCourses,
  Reviews,
  Team,
  offer,
  subsBanner,
} from "../JsonServer/localServer";
import {
  faBell,
  faBookBookmark,
  faChevronLeft,
  faChevronRight,
  faComment,
  faList,
  faPlayCircle,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  CrsModal,
  EbGnrModal,
  EbInfo,
  EbPdf,
  EbVwModal,
  ListModal,
  LoginModal,
  MvpAllModal,
  NewsCatModal,
  NewsModal,
  XmModal,
} from "./Modal";

const Responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const EbResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },

    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const vidResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },

    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const morvidResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },

    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const oneSlideRes = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const naSlideRes = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const csrlArr = (arr, dir) => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff60",
        position: "absolute",
        right: dir === "r" ? 0 : "unset",
        left: dir === "l" ? 0 : "unset",
        marginBlock: "50%",
        padding: "3.45%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <FontAwesomeIcon icon={arr} className="arr" />
    </div>
  );
};

export const Providings = ({ data }) => {
  return (
    <div className="clmDiv">
      {data.map((item, index) => (
        <div className="itmDiv" key={index}>
          <img alt="What we provide" className="itmImg" src={item.img} />
          <label className="itmHd">{item.title}</label>
          <p className="itmDesc">{item.desc}</p>
        </div>
      ))}
    </div>
  );
};

export const PopCrsCrsll = () => {
  const Responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="crsCarousel">
      <Carousel
        responsive={Responsive}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        pauseOnHover={true}
        // centerMode
      >
        {PopCourses.map((item, index) => (
          <div className="crsItem" key={index}>
            <img alt={item.title} src={item.img} className="crsImg" />
            <label className="crsTitle">{item.title}</label>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export const RvwCrsll = () => {
  return (
    <div className="rvwCarousel">
      <Carousel
        responsive={Responsive}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        pauseOnHover={true}
        centerMode
      >
        {Reviews.map((item, index) => (
          <div className="rvwItem" key={index}>
            <img alt={item.title} src={item.img} className="rvwImg" />
            <label className="colon">"</label>
            <label className="rvwName">{item.name}</label>
            <label className="rvw">{item.review}</label>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export const TeamCrsl = () => {
  return (
    <div className="teamCarousel">
      <Carousel
        responsive={Responsive}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        pauseOnHover={true}
        // centerMode
      >
        {Team.map((item, index) => (
          <div className="teamItem" key={index}>
            <img alt={item.title} src={item.img} className="teamImg" />
            <label className="teamMmbr">{item.name}</label>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export const CourseCrsl = () => {
  return (
    <div className="courseCrsl">
      <Carousel
        responsive={oneSlideRes}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        pauseOnHover={true}
      >
        {CrsSlides.map((item, index) => (
          <div className="corsItem" key={index}>
            <img alt={item.alt} src={item.img} className="corsImg" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export const EbGenres = ({ csrlData }) => {
  csrlData = csrlData.genre ? csrlData.genre : [];
  const [visible, setVisible] = useState(false);
  return (
    <div
      className="ebGnrsMain"
      style={{ display: csrlData.length === 0 ? "none" : "unset" }}
    >
      <EbGnrModal
        data={csrlData}
        visible={visible}
        setVisible={() => setVisible(!visible)}
      />
      <div className="ebGnrsHd">
        <label className="gnrTtl"> Top Genres</label>
        <div className="gnrVwAll" onClick={() => setVisible(true)}>
          <FontAwesomeIcon icon={faList} />
          &nbsp; View All
        </div>
      </div>
      <Carousel
        responsive={EbResponsive}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        pauseOnHover={true}
        // centerMode
      >
        {csrlData.map((item, index) => (
          <EbGnrBox key={index} item={item} />
        ))}
      </Carousel>
    </div>
  );
};

export const EbTopReads = ({ csrlData }) => {
  csrlData = csrlData.ebooks ? csrlData.ebooks : [];
  const [visible, setVisible] = useState(false);
  const [v, setV] = useState(false);
  const [ebItm, setEbItm] = useState([]);
  const [ebRdV, setEbRdV] = useState(false);
  const [lgMdlVsbl, setLgMdlVsbl] = useState(false);
  const isUSr = User().id;
  const force = useForceUpdate();

  let data = csrlData.filter((i) => parseFloat(i.top_read) > 1);
  data = data.slice(0, 100);

  return (
    <div
      className="ebGnrsMain"
      style={{ display: csrlData.length === 0 ? "none" : "unset" }}
    >
      <LoginModal
        visible={lgMdlVsbl}
        setVisible={() => setLgMdlVsbl(!lgMdlVsbl)}
      />
      <EbVwModal
        data={data}
        visible={visible}
        setVisible={() => setVisible(!visible)}
        name={"Top Reads"}
      />
      <EbPdf
        ebId={ebItm.id}
        pdf={ebItm.ebook_pdf}
        visible={ebRdV}
        setVisible={() => setEbRdV(!ebRdV)}
      />
      <EbInfo
        visible={v}
        setVisible={() => setV(!v)}
        item={ebItm}
        readFunc={(val) =>
          isUSr ? setEbItm(val, setEbRdV(true)) : setLgMdlVsbl(true)
        }
      />
      <div className="ebGnrsHd">
        <label className="gnrTtl"> Top Reads</label>
        <div className="gnrVwAll" onClick={() => setVisible(true)}>
          <FontAwesomeIcon icon={faList} />
          &nbsp; View All
        </div>
      </div>
      <Carousel
        responsive={EbResponsive}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        pauseOnHover={true}
      >
        {data.map((item, index) => (
          <EbookBox
            key={index}
            item={item}
            readFunc={(val) =>
              isUSr ? setEbItm(val, setEbRdV(true)) : setLgMdlVsbl(true)
            }
            setEbItm={(val) => setEbItm(val, setV(true))}
            reRender={() => force()}
          />
        ))}
      </Carousel>
    </div>
  );
};

export const EbBestSellers = ({ csrlData }) => {
  csrlData = csrlData.genre
    ? csrlData.ebooks.filter(
        (i) => i.best_seller === 1 || i.best_seller === "1"
      )
    : [];
  let data = csrlData.slice(0, 100);
  const [visible, setVisible] = useState(false);
  const [v, setV] = useState(false);
  const [ebItm, setEbItm] = useState([]);
  const [ebRdV, setEbRdV] = useState(false);
  const [lgMdlVsbl, setLgMdlVsbl] = useState(false);
  const force = useForceUpdate();

  const isUSr = User().id;

  return (
    <div
      className="ebGnrsMain"
      style={{ display: csrlData.length === 0 ? "none" : "unset" }}
    >
      <LoginModal
        visible={lgMdlVsbl}
        setVisible={() => setLgMdlVsbl(!lgMdlVsbl)}
      />
      <EbInfo
        visible={v}
        setVisible={() => setV(!v)}
        item={ebItm}
        readFunc={(val) =>
          isUSr ? setEbItm(val, setEbRdV(true)) : setLgMdlVsbl(true)
        }
      />
      <EbPdf
        ebId={ebItm.id}
        pdf={ebItm.ebook_pdf}
        visible={ebRdV}
        setVisible={() => setEbRdV(!ebRdV)}
      />
      <EbVwModal
        data={csrlData}
        visible={visible}
        setVisible={() => setVisible(!visible)}
        name={"Best Sellers"}
      />
      <div className="ebGnrsHd">
        <label className="gnrTtl">Best Sellers</label>
        <div className="gnrVwAll" onClick={() => setVisible(true)}>
          <FontAwesomeIcon icon={faList} />
          &nbsp; View All
        </div>
      </div>
      <Carousel
        responsive={EbResponsive}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        pauseOnHover={true}
      >
        {data.map((item, index) => (
          <EbookBox
            key={index}
            item={item}
            setEbItm={(val) => setEbItm(val, setV(true))}
            readFunc={(val) =>
              isUSr ? setEbItm(val, setEbRdV(true)) : setLgMdlVsbl(true)
            }
            reRender={() => force()}
          />
        ))}
      </Carousel>
    </div>
  );
};

export const UsrEbCrsl = ({ type }) => {
  const [visible, setVisible] = useState(false);
  const { ebShelf, ebConread } = usrEbook();
  const [v, setV] = useState(false);
  const [ebItm, setEbItm] = useState([]);
  const [ebRdV, setEbRdV] = useState(false);
  const [lgMdlVsbl, setLgMdlVsbl] = useState(false);
  const isUSr = User().id;
  const force = useForceUpdate();

  let data = type === "bs" ? ebShelf : ebConread;
  data = data ? data : [];
  data = new Set(data.map(JSON.stringify));
  data = Array.from(data).map(JSON.parse);

  return usrEbook().length === 0 ? null : data.length === 0 ? null : (
    <div
      className="ebGnrsMain"
      style={{ display: usrEbook().length === 0 ? "none" : "unset" }}
    >
      <LoginModal
        visible={lgMdlVsbl}
        setVisible={() => setLgMdlVsbl(!lgMdlVsbl)}
      />
      <EbInfo
        visible={v}
        setVisible={() => setV(!v)}
        item={ebItm}
        readFunc={(val) =>
          isUSr ? setEbItm(val, setEbRdV(true)) : setLgMdlVsbl(true)
        }
      />
      <EbPdf
        ebId={ebItm.id}
        pdf={ebItm.ebook_pdf}
        visible={ebRdV}
        setVisible={() => setEbRdV(!ebRdV)}
      />
      <EbVwModal
        data={data}
        visible={visible}
        setVisible={() => setVisible(!visible)}
        name={type === "bs" ? "Book Shelf" : "Continue Reading"}
      />
      <div className="ebGnrsHd">
        <label className="gnrTtl">
          {type === "bs" ? "Book Shelf" : "Continue Reading"}
        </label>
        <div className="gnrVwAll" onClick={() => setVisible(true)}>
          <FontAwesomeIcon icon={faList} />
          &nbsp; View All
        </div>
      </div>

      <Carousel
        responsive={EbResponsive}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        pauseOnHover={true}
      >
        {data.map((item, index) => (
          <EbookBox
            key={index}
            item={item}
            setEbItm={(val) => setEbItm(val, setV(true))}
            readFunc={(val) =>
              isUSr ? setEbItm(val, setEbRdV(true)) : setLgMdlVsbl(true)
            }
            reRender={() => force()}
          />
        ))}
      </Carousel>
    </div>
  );
};

export const SubsBanner = () => {
  return (
    <div className="subsOfferCrsl">
      <Carousel
        responsive={Responsive}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        pauseOnHover={true}
        centerMode
      >
        {subsBanner.map((item, index) => (
          <div className="subOItem" key={index}>
            <img alt={item.title} src={item.src} className="subOImg" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export const PopCatCrsll = ({ data }) => {
  const [csrlData, setCsrlData] = useState([]);
  const [catItem, setCatItem] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setCsrlData(data.popCat === undefined ? [] : data.popCat);
  }, [data.popCat]);

  return (
    <div className="crsCarousel">
      <CrsModal
        data={
          data.popCrs === undefined
            ? []
            : data.popCrs.filter(
                (i) => String(i.course_cat_id) === String(catItem.id)
              )
        }
        name={catItem.cat_name}
        visible={visible}
        setVisible={() => setVisible(!visible)}
      />
      <Carousel
        responsive={Responsive}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        pauseOnHover={true}
        centerMode
      >
        {csrlData.map((item, index) => (
          <div
            key={index}
            style={{ cursor: "pointer" }}
            className="crsItem"
            onClick={() => setCatItem(item, setVisible(true))}
          >
            <img
              alt={item.cat_name}
              src={`${Server.CATIMG}${item.image}`}
              className="offImg"
            />
            <label className="crsTitle">{item.cat_name}</label>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export const PopEduCrsll = ({ data }) => {
  const [csrlData, setCsrlData] = useState(data);
  useEffect(() => {
    setCsrlData(data.popEdu === undefined ? [] : data.popEdu);
  }, [data.popEdu]);

  return (
    <div className="crsCarousel">
      <Carousel
        responsive={Responsive}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        pauseOnHover={true}
        centerMode
      >
        {csrlData.map((item, index) => (
          <div className="crsItem" key={index}>
            <img
              alt={item.name}
              src={item.profile_pic}
              className="offImg"
              onError={(val) => (val.target.src = noImage)}
            />
            <label className="crsTitle">{item.name}</label>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export const PopCaCsrl = ({ data }) => {
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modIcn, setmodIcn] = useState("");
  const [modTtl, setModTtl] = useState("");
  const [caVisible, setCaVisible] = useState(false);

  return (
    <div className="crsCarousel">
      <ListModal
        title={modTtl}
        icon={modIcn}
        data={modalData}
        visible={visible}
        setVisible={() => setVisible()}
      />
      <NewsCatModal
        data={data}
        visible={caVisible}
        setVisible={() => setCaVisible(false)}
      />
      <Carousel
        responsive={Responsive}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        pauseOnHover={true}
        centerMode
      >
        <div
          style={{ cursor: "pointer" }}
          className="crsItem"
          onClick={() => {
            setModalData(data.pod === undefined ? [] : data.pod);
            setmodIcn(faPlayCircle);
            setModTtl("Podcast");
            setVisible(true);
          }}
        >
          <img alt={"Podcast"} src={podImg} className="offImg" />
          <label className="crsTitle">{"Podcast"}</label>
        </div>

        <div
          style={{ cursor: "pointer" }}
          className="crsItem"
          onClick={() => {
            setModalData(data.mag === undefined ? [] : data.mag);
            setmodIcn(faBookBookmark);
            setModTtl("Magazines");
            setVisible(true);
          }}
        >
          <img alt={"Magazines"} src={mgImg} className="offImg" />
          <label className="crsTitle">{"Magazines"}</label>
        </div>
        <div
          style={{ cursor: "pointer" }}
          className="crsItem"
          onClick={() => {
            setModalData(data.pib === undefined ? [] : data.pib);
            setmodIcn(faNewspaper);
            setModTtl("Government Report");
            setVisible(true);
          }}
        >
          <img alt={"Govt. Report"} src={pibImg} className="offImg" />
          <label className="crsTitle">{"Govt. Report"}</label>
        </div>
        <div
          className="crsItem"
          onClick={() => setCaVisible(true)}
          style={{ cursor: "pointer" }}
        >
          <img alt={"Newspaper"} src={newsImg} className="offImg" />
          <label className="crsTitle">{"Newspaper"}</label>
        </div>
      </Carousel>
    </div>
  );
};

export const CaNewsCsrl = () => {
  const navigate = useNavigate();
  const [caNews, setCaNews] = useState();

  useEffect(() => {
    const load = async () => setCaNews(await getInshorts());
    load();
  }, []);

  return (
    caNews && (
      <div className="nwsWid">
        <div className="widHead">
          <img className="nwsWdImg" alt="Current Affairs" src={caImg} />
          <p className="caTxt">
            Current Affairs
            <br />
            <span className="caTxt-alt">Latest News</span>
          </p>
          <p onClick={() => navigate(`/ca/na/0`)} className="caBtn">
            View All
          </p>
        </div>
        <Carousel
          responsive={oneSlideRes}
          swipeable={false}
          draggable={false}
          showDots={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          transitionDuration={1000}
          pauseOnHover={true}
          arrows={true}
        >
          {caNews.map((item, index) => (
            <NwsBox item={item} key={index} />
          ))}
        </Carousel>
      </div>
    )
  );
};

export const ConVidCrsl = () => {
  const { conVids } = mvpData();

  return conVids === undefined ? null : conVids.length === 0 ? null : (
    <div className="vidCsrlMain">
      <div className="vidHeadMain">
        <p className="vidTtl">Continue Watching</p>
      </div>
      <Carousel
        centerMode={window.innerWidth > 900 ? true : false}
        minimumTouchDrag={1}
        className="VidCsrl"
        responsive={vidResponsive}
        swipeable={false}
        draggable={true}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={10000}
        keyBoardControl={true}
        transitionDuration={1000}
        pauseOnHover={true}
        arrows={true}
        customRightArrow={csrlArr(faChevronRight, "r")}
        customLeftArrow={csrlArr(faChevronLeft, "l")}
      >
        {conVids.map((item, index) => (
          <VidBox item={item} key={index} isCw={true} />
        ))}
      </Carousel>
    </div>
  );
};

export const VidListCrsl = () => {
  const { freeVid } = mvpData();
  const [visible, setVisible] = useState(false);

  return (
    freeVid &&
    freeVid.length > 0 && (
      <div className="vidCsrlMain">
        <MvpAllModal
          visible={visible}
          setVisible={() => setVisible(!visible)}
          data={freeVid}
          title={"Free Videos By The Study Falcon"}
        />
        <div className="vidHeadMain">
          <p className="vidTtl">Free Videos By The Study Falcon</p>
          <p onClick={() => setVisible(true)} className="vidVwAlBtn">
            View All
          </p>
        </div>
        <Carousel
          centerMode={window.innerWidth > 900}
          minimumTouchDrag={1}
          className="VidCsrl"
          responsive={vidResponsive}
          swipeable={false}
          draggable={true}
          showDots={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          transitionDuration={300}
          pauseOnHover={true}
          arrows={true}
          customRightArrow={csrlArr(faChevronRight, "r")}
          customLeftArrow={csrlArr(faChevronLeft, "l")}
        >
          {freeVid.map((item, index) => (
            <VidBox
              item={item}
              key={index}
              educator_name={"The Study Falcon"}
            />
          ))}
        </Carousel>
      </div>
    )
  );
};

export const SubVidCsrl = ({ vidData }) => {
  vidData = vidData === undefined ? [] : vidData;
  const [visible, setVisible] = useState(false);
  const [subVids, setSubVids] = useState([]);
  const [modTtl, setModTtl] = useState("");

  return (
    <>
      <MvpAllModal
        visible={visible}
        setVisible={() => setVisible(!visible)}
        data={subVids}
        title={modTtl}
      />
      {vidData.length === 0
        ? null
        : vidData.map(
            ({ subject_name, subject_id, videos, educator_name }, index) => (
              <div
                id={subject_id}
                key={index}
                className="vidCsrlMain"
                style={{ display: videos.length === 0 ? "none" : null }}
              >
                <div className="vidHeadMain">
                  <p className="vidTtl">
                    Lecture Videos on {subject_name} By {educator_name}
                  </p>
                  <p
                    onClick={() => {
                      setSubVids(videos);
                      setModTtl(
                        `Lecture Videos on ${subject_name} By ${educator_name}`
                      );
                      setVisible(true);
                    }}
                    className="vidVwAlBtn"
                  >
                    View All
                  </p>
                </div>
                <Carousel
                  centerMode={window.innerWidth > 900 ? true : false}
                  minimumTouchDrag={1}
                  className="VidCsrl"
                  responsive={vidResponsive}
                  swipeable={false}
                  draggable={true}
                  showDots={false}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={5000}
                  keyBoardControl={true}
                  transitionDuration={1000}
                  pauseOnHover={true}
                  arrows={true}
                  customRightArrow={csrlArr(faChevronRight, "r")}
                  customLeftArrow={csrlArr(faChevronLeft, "l")}
                >
                  {videos.map((item, index) => (
                    <VidBox item={item} key={index} />
                  ))}
                </Carousel>
              </div>
            )
          )}
    </>
  );
};

export const MorVidCsrl = ({ vidData, educator_name, itm }) => {
  vidData = vidData === undefined ? [] : vidData;
  vidData = vidData.filter((i) => i.id !== itm.id);
  const ref = useRef();

  return (
    <div className="morVidCsrlMain" ref={ref}>
      <div className="morVidHeadMain">
        <p
          className="vidTtl"
          onClick={() => ref.current?.scrollIntoView({ behavior: "smooth" })}
        >
          More Videos By{" "}
          {educator_name === undefined ? "The Study Falcon" : educator_name}{" "}
        </p>
      </div>
      <Carousel
        centerMode
        minimumTouchDrag={1}
        className="VidCsrl"
        responsive={morvidResponsive}
        swipeable={false}
        draggable={true}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        pauseOnHover={true}
        arrows={true}
        customRightArrow={csrlArr(faChevronRight, "r")}
        customLeftArrow={csrlArr(faChevronLeft, "l")}
      >
        {vidData.map((item, index) => (
          <VidBox item={item} key={index} />
        ))}
      </Carousel>
    </div>
  );
};

export const ExmUpdtsCrsl = () => {
  const [xmItem, setXmItem] = useState([]);
  const [xmVisible, setXmVisible] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    const load = async () => setData(await getNewExams());
    load();
  }, []);

  return (
    data && (
      <div className="exmUpdtMain">
        <XmModal
          item={xmItem}
          visible={xmVisible}
          setVisible={() => setXmVisible(!xmVisible)}
        />
        <p className="exmHeadDiv">Exam Updates Slides</p>
        <Carousel
          responsive={oneSlideRes}
          swipeable={false}
          draggable={false}
          showDots={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          transitionDuration={1000}
          pauseOnHover={true}
        >
          {data.map(({ name, lastDate }, index) => (
            <div className="exmInfoDiv" key={index}>
              <p className="exmName">{name}</p>
              <p className="lstDate">
                Application Last Date
                <br />
                <span className="lstDate-alt">{lastDate}</span>
              </p>
              <p
                onClick={() => setXmItem(data[index], setXmVisible(true))}
                className="exmBtn"
              >
                Read More
              </p>
            </div>
          ))}
        </Carousel>
      </div>
    )
  );
};

export const DiscQuesCrsl = () => {
  const { disQues } = dashData();
  const force = useForceUpdate();
  useEffect(() => {
    const int = setInterval(() => {
      force();
    }, 1000);
    return () => clearInterval(int);
  }, [force]);
  // console.log(disQues);
  return disQues === undefined ? null : (
    <div className="disQuesMain">
      <div className="disHeadMain">
        <p className="quesTtl">Recent Questions</p>
        <p className="qVwBtn">View All</p>
      </div>
      <Carousel
        responsive={oneSlideRes}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={1000}
        pauseOnHover={true}
        arrows={false}
      >
        {disQues.map(
          ({ name, image, question, upvote, downvote, answers }, index) => (
            <div key={index} className="quesBody">
              <img
                className="usrImg"
                alt="User"
                src={"https://thestudyfalcon.com/" + image}
              />
              <div className="quesD">
                <p className="usrName">{name}</p>
                <p className="usrQues">{question}</p>
                <div className="QvotesD">
                  <p className="qIcnD">
                    <FontAwesomeIcon className="qLike" icon={faThumbsUp} />
                    &nbsp;{upvote}
                  </p>
                  <p className="qIcnD">
                    <FontAwesomeIcon className="qDisLike" icon={faThumbsDown} />
                    &nbsp;{downvote}
                  </p>
                  <p className="qIcnD">
                    <FontAwesomeIcon className="qCmmnt" icon={faComment} />
                    &nbsp;{answers}
                  </p>
                  <p className="qIcnD">
                    <FontAwesomeIcon className="qNoti" icon={faBell} />
                    &nbsp;
                  </p>
                </div>
                <p className="qRpBtn">Report Question</p>
              </div>
            </div>
          )
        )}
      </Carousel>
    </div>
  );
};

export const MvpBannerCrsl = () => {
  return (
    <div className="bnrCrsl">
      <BannerCrsl
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        animationHandler={"fade"}
        showStatus={false}
        showIndicators={false}
      >
        {offer.map((item, index) => (
          <div key={index}>
            <img alt="offer" src={item.src} />
          </div>
        ))}
      </BannerCrsl>
    </div>
  );
};

export const NaHeadsCsrl = ({ data, catId, nwsCats }) => {
  data = data ? data : [];
  const crsl = useRef();
  const [nwItm, setNwItm] = useState([]);
  const [v, setV] = useState(false);
  const { innerWidth } = window;
  let catName =
    catId === "0"
      ? ""
      : nwsCats.filter((i) => String(i.id) === String(catId))[0].cat;

  return (
    <div className="NaHdMain">
      <NewsModal item={nwItm} setV={() => setV(false)} v={v} />
      <Carousel
        ref={crsl}
        className="naCrsl"
        responsive={naSlideRes}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={10000}
        keyBoardControl={true}
        transitionDuration={500}
        pauseOnHover={true}
        arrows={true}
        centerMode={innerWidth > 800}
        shouldResetAutoplay
        customRightArrow={csrlArr(faChevronRight, "r")}
        customLeftArrow={csrlArr(faChevronLeft, "l")}
      >
        {(catId === "0"
          ? data
          : data.filter((i) =>
              String(JSON.stringify(i.category)).includes(catName)
            )
        ).map((item, index) => (
          <CaNaBox
            item={item}
            key={index}
            setNwItm={(v) => setNwItm(v)}
            setV={(v) => setV(v)}
          />
        ))}
      </Carousel>
    </div>
  );
};
